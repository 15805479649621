export default [
  {
    title: 'Dashboards',
    icontype: 'fas',
    icon: 'home',
    route: 'Dashboard',
    resource: 'dashboard',
    action: 'read',
  },
]
