export default [
  {
    header: 'Miscellaneous',
    resource: 'Misc-OneStepUp',
    action: 'read',
  },
  {
    title: 'One Step Up',
    icontype: 'fas',
    icon: 'file-invoice',
    resource: 'Misc-OneStepUp',
    action: 'read',
    children: [
      {
        title: 'Active',
        route: 'Misc-OneStepUp-Active',
        resource: 'Misc-OneStepUp',
        action: 'read',
      },
      {
        title: 'Archive',
        route: 'Misc-OneStepUp-Archive',
        resource: 'Misc-OneStepUp',
        action: 'read',
      },
    ],
  },
]
