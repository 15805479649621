export default [
  {
    header: 'Booking Platform',
    resource: 'Booking',
    resource1: 'Booking-Shipment',
    resource2: 'Booking-Customer',
    resource3: 'Booking-Carrier',
    resource4: 'Booking-Settings',
    resource5: 'Booking-Reports',
    resource6: 'Booking-ContainerTracking',
    action: 'read',
  },
  {
    title: 'Booking',
    route: 'Booking',
    icontype: 'fas',
    icon: 'file-invoice',
    resource: 'Booking',
    action: 'read',
  },
  {
    title: 'Shipment',
    route: 'Booking-Shipment',
    icontype: 'fas',
    icon: 'shipping-fast',
    resource: 'Booking-Shipment',
    action: 'read',
  },
  {
    title: 'Carriers',
    route: 'Booking-Carrier',
    icontype: 'fas',
    icon: 'truck-loading',
    resource: 'Booking-Carrier',
    action: 'read',
  },
  {
    title: 'Container Tracking',
    route: 'Booking-ContainerTracking',
    icontype: 'fas',
    icon: 'boxes',
    resource: 'Booking-ContainerTracking',
    action: 'read',
  },
  {
    title: 'Customer',
    route: 'Booking-Customer',
    icontype: 'fas',
    icon: 'user-tie',
    resource: 'Booking-Customer',
    action: 'read',
  },
  {
    title: 'Booking Settings',
    route: 'Booking-Settings',
    icontype: 'fas',
    icon: 'cogs',
    resource: 'Booking-Settings',
    action: 'read',
  },
  {
    title: 'Archive',
    icontype: 'fas',
    icon: 'archive',
    resource: 'Booking',
    resource1: 'Booking-Shipment',
    action: 'read',
    children: [
      {
        title: 'Booking Archive',
        route: 'Booking-Archive',
        resource: 'Booking',
        action: 'read',
      },
      {
        title: 'Shipment Archive',
        route: 'Booking-Shipments-Archive',
        resource: 'Booking-Shipment',
        action: 'read',
      },
    ],
  },
  {
    title: 'Reports',
    icontype: 'fas',
    icon: 'chart-bar',
    resource: 'Booking-Reports',
    action: 'read',
    children: [
      {
        title: 'DSR',
        route: 'Booking-Reports-DSR',
        resource: 'Booking-Reports-DSR',
        action: 'read',
      },
      {
        title: 'Container Status',
        route: 'Booking-Reports-ContainerStatus',
        resource: 'Booking-Reports-ContainerStatus',
        action: 'read',
      },
    ],
  },
]
