export default [
  {
    header: 'ABI Platform',
    resource: 'ABI-ES',
    resource1: 'ABI-ISF',
    resource2: 'ABI-Query',
    resource3: 'ABI-Party',
    resource4: 'ABI-Statement',
    resource5: 'ABI-Settings',
    action: 'read',
  },
  {
    title: 'Entry Summary',
    route: 'ABI-ES',
    icontype: 'fas',
    icon: 'file-invoice',
    resource: 'ABI-ES',
    action: 'read',
  },
  {
    title: 'ISF',
    route: 'ABI-ISF',
    icontype: 'fas',
    icon: 'ship',
    resource: 'ABI-ISF',
    action: 'read',
  },
  {
    title: 'Customers',
    route: 'ABI-Customers',
    icontype: 'fas',
    icon: 'user-tie',
    resource: 'ABI-Party',
    resource1: 'ABI-ISF',
    resource2: 'ABI-ES',
    action: 'read',
  },
  {
    title: 'Parties',
    route: 'ABI-Parties',
    icontype: 'fas',
    icon: 'user-tie',
    resource: 'ABI-Party',
    resource1: 'ABI-ISF',
    resource2: 'ABI-ES',
    action: 'read',
  },
  {
    title: 'HTS',
    route: 'ABI-HTS',
    icontype: 'fas',
    icon: 'book',
    resource: 'ABI-ES',
    action: 'read',
  },
  {
    title: 'Query',
    icontype: 'far',
    icon: 'question-circle',
    resource: 'ABI-Query',
    action: 'read',
    children: [
      {
        title: 'AD/CVD',
        route: 'ABI-Query-ADCVD',
        resource: 'ABI-Query',
        action: 'read',
      },
      {
        title: 'Cargo Status',
        route: 'ABI-Query-CargoStatus',
        resource: 'ABI-Query',
        action: 'read',
      },
      // {
      //   title: 'Entry Summary',
      //   route: 'ABI-Query-ES',
      //   resource: 'ABI-Query',
      //   action: 'read',
      // },
      // {
      //   title: 'Importers',
      //   route: 'ABI-Query-Importer',
      //   resource: 'ABI-Query',
      //   action: 'read',
      // },
      {
        title: 'MID',
        route: 'ABI-Query-MID',
        resource: 'ABI-Query',
        action: 'read',
      },
    ],
  },
  {
    title: 'Statement',
    icontype: 'fas',
    icon: 'file-invoice-dollar',
    resource: 'ABI-Statement',
    action: 'read',
    children: [
      {
        title: 'Daily Statement',
        route: 'ABI-Statement-Daily',
        resource: 'ABI-Statement',
        action: 'read',
      },
      {
        title: 'Monthly Statement',
        route: 'ABI-Statement-Monthly',
        resource: 'ABI-Statement',
        action: 'read',
      },
    ],
  },
  {
    title: 'Reference Extract',
    icontype: 'far',
    icon: 'file',
    children: [
      {
        title: 'Carriers',
        route: 'ABI-Extract-Carriers',
        resource: 'ABI-ES',
        resource1: 'ABI-ISF',
        action: 'read',
      },
      {
        title: 'Countries',
        route: 'ABI-Extract-Country',
        resource: 'ABI-ES',
        resource1: 'ABI-ISF',
        action: 'read',
      },
      {
        title: 'Foreign Ports',
        route: 'ABI-Extract-ForeignPort',
        resource: 'ABI-ES',
        resource1: 'ABI-ISF',
        action: 'read',
      },
      {
        title: 'FIRMS',
        route: 'ABI-Extract-FIRMS',
        resource: 'ABI-ES',
        resource1: 'ABI-ISF',
        action: 'read',
      },
      {
        title: 'Region/District/Port',
        route: 'ABI-Extract-Port',
        resource: 'ABI-ES',
        resource1: 'ABI-ISF',
        action: 'read',
      },
    ],
  },
  {
    title: 'ABI Settings',
    icontype: 'fas',
    icon: 'cogs',
    resource: 'ABI-Settings',
    action: 'read',
    children: [
      {
        title: 'Queue Setup',
        route: 'ABI-Settings-Queue',
        resource: 'ABI-Settings',
        action: 'read',
      },
      {
        title: 'Modules',
        route: 'ABI-settings-Modules',
        resource: 'ABI-Settings',
        action: 'read',
      },
      // {
      //   title: 'User Modules',
      //   route: 'ABI-settings-Users',
      //   resource: 'ABI-Settings',
      //   action: 'read',
      // },
    ],
  },
]
