<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ activeUserInfo.displayName }}
        </p>
      </div>
      <b-avatar
        v-if="activeUserInfo.photoURL"
        size="40"
        variant="light-primary"
        badge
        :src="activeUserInfo.photoURL"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <!-- <b-dropdown-item

      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-divider /> -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'User-Account' }"
    >
      <font-awesome-icon
        :icon="['fas', 'cog']"
        class="mr-50"
        size="lg"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <font-awesome-icon
        :icon="['fas', 'sign-out-alt']"
        class="mr-50"
        size="lg"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, // BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import Cookies from 'js-cookie'
import SecureLS from 'secure-ls'
import { initialAbility } from '@/libs/acl/config'

const ls = new SecureLS({ isCompression: false, encodingType: 'des' })

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  methods: {
    logout() {
      ls.remove('userInfo')
      ls.remove('roleInfo')
      ls.remove('claimInfo')
      ls.remove('vuex')
      Cookies.remove('user')
      this.$ability.update(initialAbility)
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$router.push('/login')
        })
    },
  },
}
</script>
